import React from "react";

import "../SlidingBackground.css";
import Footer from "../components/Footer";
import HeroSection from "../components/HeroSection";
import PromisesComponent from "../components/stracaPage/PromisesComponent";
import Timeline from "../components/stracaPage/Timeline";
import VisionComponent from "../components/stracaPage/VisionComponent";
import TeamComponent from "../components/stracaPage/TeamComponent";

const Straca = () => {
  return (
    <div className="w-screen  bg-white  flex flex-col overflow-hidden">
      <HeroSection url={"/new/hero-img-1.png"}>
        <div className="rounded-xl bg-main-orange bg-opacity-65 mt-[25vh] md:mt-[50vh] mb-36 ml-10 md:ml-40 pl-10 py-10  pr-10 md:pr-40 w-fit  flex flex-row items-start justify-start text-left  text-white font-urbanist">
          <h1 className="relative  font-light  leading-snug text-2xl md:text-4xl ">
            <span className="font-medium">Ensemble</span>, <br />
            sur la route de nos <br />
            <span className="font-medium">accomplissements</span>
          </h1>
        </div>
      </HeroSection>
      <PromisesComponent />
      <Timeline />
      <VisionComponent />

      <TeamComponent />
      <Footer />
    </div>
  );
};

export default Straca;
