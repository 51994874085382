import { useEffect } from "react";

import {
  Route,
  Routes,
  useLocation,
  useNavigationType,
} from "react-router-dom";
import CultureSavoirFaire from "./pages/culture/CultureSavoirFaire";
import LoginClient from "./pages/LoginClient";
import Expertise from "./pages/Expertise";
import Collaborons from "./pages/Collaborons";
import PARTENAIRES from "./pages/PARTENAIRES";
import Engagement from "./pages/culture/Engagement";
import Certifications from "./pages/culture/Certifications";
import CultureActualites from "./pages/culture/CultureActualites";
import Straca from "./pages/Straca";
import HomePage from "./pages/HomePage";
import CultureMainPage from "./pages/culture/CultureMainPage";
import Projets from "./pages/Projets";
import Blog from "./pages/Blog";
import { useAuth } from "./context/AuthContext";

function App() {
  const action = useNavigationType();
  const location = useLocation();
  const pathname = location.pathname;
  const { user } = useAuth();

  useEffect(() => {
    if (action !== "POP") {
      window.scrollTo(0, 0);
    }
  }, [action, pathname]);

  useEffect(() => {
    let title = "";
    let metaDescription = "";

    switch (pathname) {
      case "/":
        title = "";
        metaDescription = "";
        break;
      case "/espace-client3":
        title = "";
        metaDescription = "";
        break;
      case "/espace-client4":
        title = "";
        metaDescription = "";
        break;
      case "/espace-client1":
        title = "";
        metaDescription = "";
        break;
      case "/espace-client":
        title = "";
        metaDescription = "";
        break;
      case "/espace-client2":
        title = "";
        metaDescription = "";
        break;
      case "/macbook-pro-14-2":
        title = "";
        metaDescription = "";
        break;
      case "/expeertise":
        title = "";
        metaDescription = "";
        break;
      case "/projets":
        title = "";
        metaDescription = "";
        break;
      case "/collaborons":
        title = "";
        metaDescription = "";
        break;
      case "/partenaires":
        title = "";
        metaDescription = "";
        break;
      case "/culture/engagement":
        title = "";
        metaDescription = "";
        break;
      case "/culture/certification":
        title = "";
        metaDescription = "";
        break;
      case "/culture/actualite":
        title = "";
        metaDescription = "";
        break;
      case "/straca":
        title = "";
        metaDescription = "";
        break;
      case "/acceuil-1":
        title = "";
        metaDescription = "";
        break;
    }

    if (title) {
      document.title = title;
    }

    if (metaDescription) {
      const metaDescriptionTag = document.querySelector(
        'head > meta[name="description"]',
      );
      if (metaDescriptionTag) {
        metaDescriptionTag.content = metaDescription;
      }
    }
  }, [pathname]);

  return (
    <Routes>
      <Route path="/" element={<HomePage />} />

      <Route path="/client">
        <Route path="/client/login" element={<LoginClient />} />
      </Route>

      <Route path="/expeertise" element={<Expertise />} />
      <Route path="/project">
        <Route path=":slug" element={<Projets />} />
      </Route>
      <Route path="/collaborons" element={<Collaborons />} />
      <Route path="/partenaires" element={<PARTENAIRES />} />
      <Route path="/straca" element={<Straca />} />

      <Route path="/culture" element={<CultureMainPage />}>
        <Route path="actualite" element={<CultureActualites />} />
        <Route path="savoir-faire" element={<CultureSavoirFaire />} />
        <Route path="certification" element={<Certifications />} />
        <Route path="engagement" element={<Engagement />} />
      </Route>

      <Route path="/blog">
        <Route path=":slug" element={<Blog />} />
      </Route>
    </Routes>
  );
}
export default App;
