import React, { useEffect, useMemo, useState } from "react";
import ApplyForJob from "./ApplyForJob";
import Footer from "../components/Footer";
import { Header } from "../components/Header";
import {
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
} from "@headlessui/react";
import { TiArrowSortedDown } from "react-icons/ti";
import { FaCheck, FaFrancSign, FaRegClock } from "react-icons/fa6";
import { FiPhoneCall } from "react-icons/fi";
import { LiaFaxSolid } from "react-icons/lia";
import { BsEnvelopePaper } from "react-icons/bs";
import { BiSearch, BiX } from "react-icons/bi";
import { IoCalendarClearOutline, IoLocationOutline } from "react-icons/io5";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { useQuery } from "@tanstack/react-query";
import { getJobs, getJobsFilters } from "../api";
import { getTimeFilters, isLessThanTwoWeeks } from "../utils";
import clsx from "clsx";
import buildFormatter from "react-timeago/lib/formatters/buildFormatter";
import ReactTimeago from "react-timeago";

const Collaborons = () => {
  const people = [
    { id: 1, name: "Ressources Humaines" },
    { id: 2, name: "Service client" },
  ];

  // State for newsletter popup
  const [popupVisible, setPopupVisible] = useState(false);
  const [newsLetterMessage] = useState("");
  //const [email, setEmail] = useState("");

  // State for apply popup
  const [applyVisible, setApplyVisible] = useState(false);

  const [selected, setSelected] = useState("");

  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [total, setTotal] = useState(0);

  const [filterValues, setFilterValues] = useState({
    proximity: [],
    date: "date-0",
    contractTypes: [],
    jobTitles: [],
  });
  const [search, setSearch] = useState("");
  const [searchInput, setSearchInput] = useState(search);

  const [jobs, setJobs] = useState([]);

  // Fetch Jobs Filters
  const {
    isPending: isPendingFilters,
    //error: errorFilters,
    data: dataFilters,
    isSuccess: isSuccessFilters,
  } = useQuery({
    enabled: true,
    queryKey: ["getJobsFilters"],
    queryFn: () => getJobsFilters(),
  });

  const jobsFilters = useMemo(() => {
    if (isSuccessFilters) {
      let filters = [
        {
          name: "proximity",
          type: "checkbox",
          title: "Proximité",
          multiple: true,
          selected: [],
          data: [],
        },
        {
          name: "date",
          type: "radio",
          title: "Date d'ajout",
          multiple: false,
          selected: "date-0",
          data: getTimeFilters(),
        },
        {
          name: "contractTypes",
          type: "checkbox",
          title: "Type de contrats",
          multiple: true,
          selected: [],
          data: [],
        },
        {
          name: "jobTitles",
          type: "checkbox",
          title: "Poste",
          multiple: true,
          selected: [],
          data: [],
        },
      ];

      const proximityIndex = filters.findIndex(
        (item) => item.name === "proximity",
      );
      if (proximityIndex !== -1)
        filters[proximityIndex].data = dataFilters.proximity;

      const contractTypesIndex = filters.findIndex(
        (item) => item.name === "contractTypes",
      );
      if (contractTypesIndex !== -1)
        filters[contractTypesIndex].data = dataFilters.contractTypes;

      const jobTitlesIndex = filters.findIndex(
        (item) => item.name === "jobTitles",
      );
      if (jobTitlesIndex !== -1)
        filters[jobTitlesIndex].data = dataFilters.jobTitles;

      return filters;
    } else return [];
  }, [dataFilters, isSuccessFilters]);

  const formatFilters = useMemo(() => {
    const filterData = {};
    const newFilters = {};

    for (let jf of jobsFilters) {
      filterData[jf.name] = jf.data;
    }

    if (filterValues.contractTypes.length > 0)
      newFilters["contractTypes"] = filterValues.contractTypes.map(
        (filterValue) => {
          const currentFilter = filterData.contractTypes.find(
            (item) => item.id === filterValue,
          );
          return currentFilter?.value;
        },
      );

    if (filterValues.proximity.length > 0)
      newFilters["proximity"] = filterValues.proximity.map((filterValue) => {
        const currentFilter = filterData.proximity.find(
          (item) => item.id === filterValue,
        );
        return currentFilter?.value;
      });

    if (filterValues.jobTitles.length > 0)
      newFilters["jobTitles"] = filterValues.jobTitles.map((filterValue) => {
        const currentFilter = filterData.jobTitles.find(
          (item) => item.id === filterValue,
        );
        return currentFilter?.value;
      });

    if (filterValues.date.length > 0) {
      if (filterValues.date !== "date-0") {
        newFilters["startDate"] = filterData.date.find(
          (item) => item.id === filterValues.date,
        )?.value;

        newFilters["endDate"] = new Date(Date.now()).toISOString();
      }
    }

    return newFilters;
  }, [filterValues, jobsFilters]);

  // Fetch jobs
  const {
    isPending: isPendingJobs,
    //error: errorJobs,
    data: dataJobs,
    isSuccess: isSuccessJobs,
  } = useQuery({
    enabled: true,
    queryKey: ["getJobs", page, formatFilters, search],
    queryFn: () => {
      return getJobs({
        page,
        limit: 10,
        search: search,
        proximity: formatFilters?.proximity ?? [],
        contractTypes: formatFilters?.contractTypes ?? [],
        jobTitles: formatFilters?.jobTitles ?? [],
        startDate: formatFilters?.startDate ?? "",
        endDate: formatFilters?.endDate ?? "",
      });
    },
  });

  // Handlers
  const togglePopupVisible = () => {
    setPopupVisible(!popupVisible);
  };

  const toggleApplyVisible = () => {
    setApplyVisible(!applyVisible);
  };

  /*const sendNewsLetterEmail = async () => {
    try {
      if (email) {
        await axios.post(`${serverUrl()}/newsLetter`, {
          email,
        });

        setNewsLetterMessage("Your email will now receive our newsletters");
        togglePopupVisible();
      }
    } catch (error) {
      const errorMessage = error.response.data.message;
      console.log(errorMessage);
      setNewsLetterMessage(errorMessage);
      togglePopupVisible();
    }
  };*/

  const myStrings = {
    // environ ~= about, it's optional
    prefixAgo: "il y a",
    prefixFromNow: "d'ici",
    seconds: "moins d'une minute",
    minute: "une minute",
    minutes: "%d minutes",
    hour: "une heure",
    hours: "%d heures",
    day: "un jour",
    days: "%d jours",
    month: "un mois",
    months: "%d mois",
    year: "un an",
    years: "%d ans",
  };

  const formatter = buildFormatter(myStrings);

  useEffect(() => {
    if (isSuccessJobs) {
      setTotalPages(dataJobs.totalPages);
      setTotal(dataJobs.total);
      setJobs(dataJobs.jobs);
    }
  }, [dataJobs, isSuccessJobs, page]);

  return (
    <div className="w-screen  bg-white  flex flex-col overflow-hidden">
      <section className="flex flex-col px-8 md:px-32 py-10 font-urbanist items-center">
        <Header
          logo="/straca_benin/straca_benin.svg"
          textColor="text-black"
          buttonColor="bg-main-color"
          btnTextColor="text-white"
          decorationColor="decoration-black"
        />
        <div className="w-full mt-12 flex flex-col gap-10 items-center">
          <h1 className=" text-4xl font-medium">Collaborons !</h1>
          <p className="text-base">
            {
              "Enim tempor eget pharetra facilisis sed maecenas adipiscing. Eu leo molestie vel, ornare non id blandit netus."
            }
          </p>
          <div className="w-full flex  shadow-sm">
            <div className="relative flex flex-grow items-stretch focus-within:z-10">
              <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                <BiSearch
                  aria-hidden="true"
                  className="h-5 w-5 text-gray-400"
                />
              </div>
              <input
                id="email"
                name="email"
                type="email"
                value={searchInput}
                onChange={(e) => {
                  setSearchInput(e.target.value);
                }}
                placeholder="Quel poste recherchez vous ?"
                className="block w-full  border-0 py-3 px-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-500  focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
              <button
                className={clsx(
                  searchInput.length === 0 && search.length === 0 && "hidden",
                  "absolute inset-y-0 right-0 flex items-center pr-3",
                )}
                onClick={() => {
                  setSearchInput("");
                  setSearch("");
                }}
              >
                <BiX aria-hidden="true" className="h-7 w-7 text-gray-600" />
              </button>
            </div>
            <button
              type="button"
              className="relative -ml-px inline-flex items-center gap-x-1.5  px-7 py-2 text-sm font-semibold text-white ring-1 ring-inset ring-gray-300 bg-real-main-color hover:bg-opacity-80"
              onClick={() => {
                setSearch(searchInput);
              }}
            >
              Rechercher
            </button>
          </div>
        </div>
      </section>

      <section className="w-full h-[225vh] lg:h-[100vh] flex flex-col lg:flex-row p-10 gap-8 bg-ghostwhite-200 font-dm-sans">
        <div className="lg:w-1/6 h-96 lg:h-full p-4 bg-white flex flex-col gap-5 overflow-y-scroll">
          <h1 className="text-base font-medium">Filtres</h1>
          {isPendingFilters ? (
            <div />
          ) : (
            <>
              {jobsFilters.map((filter, index) => (
                <fieldset key={`filter-group-${index}`}>
                  <legend className="text-sm font-medium leading-6 text-gray-900">
                    {filter.title}
                  </legend>
                  <div className="mt-3 space-y-2">
                    {filter.data.map((fData) => (
                      <div key={fData.id} className="flex items-center">
                        <input
                          checked={
                            filter.type === "radio"
                              ? filterValues[filter.name] === fData.id
                              : filterValues[filter.name].includes(fData.id)
                          }
                          id={fData.id}
                          name={filter.name}
                          type={filter.type === "radio" ? "radio" : "checkbox"}
                          value={fData.value}
                          onChange={(e) => {
                            if (e.target.checked) {
                              setFilterValues((prevState) => {
                                const newPrevState = { ...prevState };

                                if (filter.multiple)
                                  newPrevState[filter.name] = [
                                    ...newPrevState[filter.name],
                                    fData.id,
                                  ];
                                else {
                                  newPrevState[filter.name] = fData.id;
                                }

                                return newPrevState;
                              });
                            } else {
                              setFilterValues((prevState) => {
                                const newPrevState = { ...prevState };

                                if (filter.multiple)
                                  newPrevState[filter.name] = newPrevState[
                                    filter.name
                                  ].filter((item) => item !== fData.id);
                                else {
                                  newPrevState[filter.name] = "";
                                }

                                return newPrevState;
                              });
                            }
                          }}
                          className="h-4 w-4 border-gray-300 text-main-orange focus:ring-0 focus:ring-offset-0"
                        />
                        <label
                          htmlFor={fData.id}
                          className="ml-3 block text-sm font-medium leading-6 text-gray-900"
                        >
                          {fData.label}
                        </label>
                      </div>
                    ))}
                  </div>
                </fieldset>
              ))}
            </>
          )}
        </div>
        {isPendingJobs ? (
          <div className="h-[100vh] lg:w-4/6" />
        ) : (
          <div className="lg:w-4/6 h-[100vh] flex flex-col gap-8">
            <span className="text-2xl font-medium">{total} postes</span>

            <div className="flex flex-col gap-5 overflow-y-scroll">
              {jobs.map((job, index) => (
                <div
                  key={`job-column-${index}`}
                  className="w-full bg-white flex flex-col lg:flex-row rounded-md p-5 gap-5"
                >
                  <img
                    className="h-20 w-20 relative rounded overflow-hidden shrink-0 object-cover"
                    loading="eager"
                    alt=""
                    src="/apply-1.png"
                  />
                  <div className="w-full flex flex-col gap-3">
                    <span className="text-lg">{`Straca ${job.proximity.label}`}</span>
                    <div className="flex flex-col lg:flex-row items-start lg:items-center gap-2">
                      <span className="text-xl font-medium">{job.post}</span>
                      <div
                        className={clsx(
                          !isLessThanTwoWeeks(job.updatedAt) && "hidden",
                          "bg-[#E6883C] bg-opacity-20 p-1 text-xs font-medium",
                        )}
                      >
                        {"Nouveau poste"}
                      </div>
                    </div>
                    <div className="w-full flex flex-col lg:flex-row items-start lg:items-center gap-5 lg:gap-10 break-words font-light">
                      <div className="flex flex-row items-center gap-1.5 text-main-color">
                        <IoLocationOutline className="h-4 w-4 " />
                        <span className="max-w-36">{job.proximity.label}</span>
                      </div>
                      <div className="h-1 w-1 bg-black rounded-full" />
                      <div className=" flex flex-row items-center gap-1.5 text-black">
                        <FaRegClock className="h-4 w-4 " />
                        <span className="max-w-36">
                          {job.contractType.label}
                        </span>
                      </div>
                      <div className=" h-1 w-1 bg-black rounded-full" />
                      <div className=" flex flex-row items-center gap-1.5 text-black">
                        <FaFrancSign className="h-4 w-4 " />
                        <span className="max-w-36 ">{job.salary}</span>
                      </div>
                      <div className="h-1 w-1 bg-black rounded-full" />
                      <div className="flex flex-row items-center gap-1.5 text-black">
                        <IoCalendarClearOutline className="h-4 w-4 " />
                        <div className="max-w-36">
                          <ReactTimeago
                            formatter={formatter}
                            date={job.updatedAt}
                          />
                        </div>
                      </div>
                    </div>
                    <p className="text-lg font-light">
                      {
                        "Mollit in laborum tempor Lorem incididunt irure. Aute eu ex ad sunt. Pariatur sint culpa do incididunt eiusmod eiusmod culpa. laborum tempor Lorem incididunt."
                      }
                    </p>
                  </div>
                </div>
              ))}
            </div>

            <div
              className={clsx(
                total === 0 && "hidden",
                "my-auto flex flex-row items-center justify-center gap-3",
              )}
            >
              <button
                disabled={page === 1}
                onClick={() => {
                  setPage((prevState) => prevState + 1);
                }}
                className=" w-10 h-10 flex items-center justify-center border"
              >
                <IoIosArrowBack className="h-4 w-4" />
              </button>
              <div className="bg-white w-10 h-10 px-4 flex items-center justify-center border">
                {page}
              </div>
              <button
                disabled={page === totalPages}
                onClick={() => {
                  setPage((prevState) => prevState - 1);
                }}
                className="bg-white w-10 h-10 flex items-center justify-center border"
              >
                <IoIosArrowForward className="h-4 w-4" />
              </button>
            </div>
          </div>
        )}
        <div className="lg:w-1/6 flex flex-col gap-5">
          <div className="flex flex-col items-center w-full h-fit bg-white p-4 gap-5">
            <span className="font-medium">
              📨&nbsp;&nbsp;&nbsp;Email de travail
            </span>
            <p className="text-sm text-black text-opacity-60 text-start">
              Ut esse eiusmod aute. Sit enim labore dolore. Aute ea fugiat
              commodo ea foes.
            </p>
            <div className="w-full">
              <input
                id="add-job-email"
                name="email"
                type="email"
                placeholder="nom@example.com"
                className="rounded-sm block w-full border-0 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </div>
            <button className="rounded-sm w-full flex justify-center py-2 border border-main-color text-main-color text-sm">
              {"Souscrire"}
            </button>
          </div>
          <div className="flex flex-col items-center w-full h-fit bg-white p-4 gap-5">
            <span className="font-medium">
              🚀&nbsp;&nbsp;&nbsp;Postulez rapidement
            </span>
            <p className="text-sm text-black text-opacity-60 text-start">
              {
                "Quis eiusmod deserunt cillum laboris magna cupidatat esse labore irure quis cupidatat in."
              }
            </p>

            <button className="rounded-sm w-full flex justify-center py-2 bg-real-main-color text-white text-sm">
              {"Télécharger CV"}
            </button>
          </div>
        </div>
      </section>

      <section className="px-8 py-16 w-full flex items-center justify-center font-montserrat">
        <div className="w-full flex flex-col items-center text-center gap-8 max-w-xl">
          <h3 className="text-4xl font-semibold">
            {"Devenez clients à la STRACA"}
          </h3>
          <p className="font-semibold text-sm text-main-orange max-w-md">
            {
              "Enim tempor eget pharetra facilisis sed maecenas adipiscing. Eu leo molestie vel, ornare non id blandit netus."
            }
          </p>
          <div className="flex flex-col w-full gap-5">
            <div className="flex rounded-xl shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 ">
              <label
                htmlFor="contact-user-name"
                className="flex select-none items-center pl-3 text-gray-500 sm:text-sm"
              >
                Nom <span className="ml-1 text-red-500">*</span>
              </label>
              <input
                id="contact-user-name"
                name="contact-user-name"
                type="text"
                placeholder=""
                className="block flex-1 border-0 bg-transparent py-2.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 text-sm leading-6"
              />
            </div>
            <div className="flex rounded-xl shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 ">
              <label
                htmlFor="contact-user-email"
                className="flex select-none items-center pl-3 text-gray-500 sm:text-sm"
              >
                Email <span className="ml-1 text-red-500">*</span>
              </label>
              <input
                id="contact-user-email"
                name="contact-user-email"
                type="email"
                placeholder=""
                className="block flex-1 border-0 bg-transparent py-2.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 text-sm leading-6"
              />
            </div>
            <div className="flex rounded-xl shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 ">
              <label
                htmlFor="contact-user-number"
                className="flex select-none items-center pl-3 text-gray-500 sm:text-sm"
              >
                Numéro <span className="ml-1 text-red-500">*</span>
              </label>
              <input
                id="contact-user-number"
                name="contact-user-number"
                type="text"
                placeholder=""
                className="block flex-1 border-0 bg-transparent py-2.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 text-sm leading-6"
              />
            </div>
            <Listbox value={selected} onChange={setSelected}>
              <div className="relative">
                <ListboxButton className="relative w-full cursor-default rounded-xl bg-white py-2.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6">
                  <span className="block truncate">
                    {selected.length > 0
                      ? selected.name
                      : "Pourquoi voulez être client à la STRACA"}
                  </span>
                  <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                    <TiArrowSortedDown
                      aria-hidden="true"
                      className="h-5 w-5 text-black"
                    />
                  </span>
                </ListboxButton>

                <ListboxOptions
                  transition
                  className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none data-[closed]:data-[leave]:opacity-0 data-[leave]:transition data-[leave]:duration-100 data-[leave]:ease-in sm:text-sm"
                >
                  {people.map((person) => (
                    <ListboxOption
                      key={person.id}
                      value={person}
                      className="group relative cursor-default select-none py-2 pl-3 pr-9 text-gray-900 data-[focus]:bg-indigo-600 data-[focus]:text-white"
                    >
                      <span className="block truncate font-normal group-data-[selected]:font-semibold">
                        {person.name}
                      </span>

                      <span className="absolute inset-y-0 right-0 flex items-center pr-4 text-indigo-600 group-data-[focus]:text-white [.group:not([data-selected])_&]:hidden">
                        <FaCheck aria-hidden="true" className="h-5 w-5" />
                      </span>
                    </ListboxOption>
                  ))}
                </ListboxOptions>
              </div>
            </Listbox>
            <button
              type="submit"
              className="bg-real-main-color text-white font-semibold uppercase flex justify-center p-2.5 rounded-xl"
            >
              Devenir client
            </button>
          </div>
          <div className="my-8 flex flex-col md:flex-row font-montserrat gap-10">
            <div className="flex flex-row items-center gap-3 ">
              <FiPhoneCall className="h-7 w-7" />
              <div className="flex flex-col text-sm items-start">
                <span className="uppercase font-semibold">Téléphone</span>
                <span className="text-gray-400">229 00 00 00 00</span>
              </div>
            </div>
            <div className="flex flex-row items-center gap-3 ">
              <LiaFaxSolid className="h-7 w-7" />
              <div className="flex flex-col text-sm items-start">
                <span className="uppercase font-semibold">Fax</span>
                <span className="text-gray-400">00 00 00 00 00</span>
              </div>
            </div>
            <div className="flex flex-row items-center gap-3 ">
              <BsEnvelopePaper className="h-7 w-7" />
              <div className="flex flex-col text-sm items-start">
                <span className="uppercase font-semibold">Email</span>
                <span className="text-gray-400">info@email.com</span>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
      {popupVisible && (
        <div
          role={"presentation"}
          className="z-[3] fixed inset-0 flex items-center justify-center bg-black bg-opacity-50"
          onClick={togglePopupVisible}
        >
          <div className="w-1/5 bg-white p-6 rounded-xl text-center">
            <p>{newsLetterMessage}</p>
            <button
              onClick={togglePopupVisible}
              className="mt-4 bg-real-main-color hover:bg-blue-500 text-white font-bold py-2 px-4 rounded"
            >
              OK
            </button>
          </div>
        </div>
      )}
      {applyVisible && <ApplyForJob onClose={toggleApplyVisible} />}
    </div>
  );
};

export default Collaborons;
