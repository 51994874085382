export const dataDigitalBestSeller = [
  {
    id: 1,
    title: "Mario Kart™ 8 Deluxe",
    linkImg:
      "https://www.industrytap.com/wp-content/uploads/2024/01/steve-doig-1UQXyYjJ79o-unsplash.jpg",
  },
  {
    id: 2,
    title: "TRIANGLE STRATEGY™",
    linkImg:
      "https://s3-alpha-sig.figma.com/img/8dd0/bf70/99c0fbcacda09fa1e68472c429ed9907?Expires=1708905600&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=Az4gA01CgT6OcQgGX7kh8eS7lAFefofgszdORoLqEfzDE7MWlSH6r59LeVMY5DXw6k2Igco4tGq0jzkCXz3PxrpzVXoBtgeA9-SI8Rmay5MVDDlMVAJd8qMFtVN0KjG6NKiD7iclL77SSUWE9x~gHTkgsETDesn3jl7dkhSdpEQ42d7GtRSTz49YRCUjzYC4Gw7fSXsYpf775UO-6RuhssiZ1IiNmpB~9PIe9-2InLHPVcXaq1ceUTWg0slD97rEgPxa6Q4wICZ5v7OxQjw3uGhTrig-cdeB9SomxemVRUHK8NDtENzP8cROIeLLpulZ-O69jo8wn9jtLw2pil0MUA__",
  },
  {
    id: 3,
    title: "Pokémon™ Legends: Arceus",
    linkImg:
      "https://images.unsplash.com/photo-1605705658744-45f0fe8f9663?q=80&w=2187&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
  },
  {
    id: 4,
    title: "Super Mario™ 3D World + Bowser’s Fury",
    linkImg:
      "https://images.unsplash.com/photo-1567789884554-0b844b597180?q=80&w=1740&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
  },
  {
    id: 5,
    title: "Cuphead",
    linkImg:
      "https://images.unsplash.com/photo-1564182842834-681b7be6de4b?q=80&w=2264&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
  },
  {
    id: 6,
    title: "Minecraft",
    linkImg:
      "https://www.suplextrade.com/imagens/andy-li-CpsTAUPoScw-unsplash%201.png",
  },
  {
    id: 7,
    title: "Mario + Rabbids® Kingdom Battle",
    linkImg:
      "https://s3-alpha-sig.figma.com/img/8dd0/bf70/99c0fbcacda09fa1e68472c429ed9907?Expires=1708905600&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=Az4gA01CgT6OcQgGX7kh8eS7lAFefofgszdORoLqEfzDE7MWlSH6r59LeVMY5DXw6k2Igco4tGq0jzkCXz3PxrpzVXoBtgeA9-SI8Rmay5MVDDlMVAJd8qMFtVN0KjG6NKiD7iclL77SSUWE9x~gHTkgsETDesn3jl7dkhSdpEQ42d7GtRSTz49YRCUjzYC4Gw7fSXsYpf775UO-6RuhssiZ1IiNmpB~9PIe9-2InLHPVcXaq1ceUTWg0slD97rEgPxa6Q4wICZ5v7OxQjw3uGhTrig-cdeB9SomxemVRUHK8NDtENzP8cROIeLLpulZ-O69jo8wn9jtLw2pil0MUA__",
  },
  {
    id: 9,
    title: "Cuphead 2",
    linkImg:
      "https://images.unsplash.com/photo-1690719083757-717499db238c?q=80&w=2940&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
  },
];
