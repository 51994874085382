import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { FaCirclePlay } from "react-icons/fa6";
import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FaArrowRight } from "react-icons/fa";
import { getBlogs } from "../../api";
import { useQuery } from "@tanstack/react-query";
import { getTimeDifference } from "../../utils";

const CultureSavoirFaire = () => {
  const videos = [
    {
      link: "https://cdn.pixabay.com/video/2016/07/08/3741-174188012_large.mp4",
      text: "Lorem ipsum Lorem ipsum Lorem ipsum v Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum vLorem ipsum Lorem ipsum Lorem ipsum Lorem ipsumLorem ipsum",
      time: "il y a 2 jours",
    },
    {
      link: "https://cdn.pixabay.com/video/2016/07/08/3741-174188012_large.mp4",
      text: "Rénnovation de votre nouveau Garage",
      time: "il y a 2 semaines",
    },
    {
      link: "https://cdn.pixabay.com/video/2016/07/08/3741-174188012_large.mp4",
      text: "Production 200 nouveaux camions",
      time: "il y a 2 semaines",
    },
    {
      link: "https://cdn.pixabay.com/video/2016/07/08/3741-174188012_large.mp4",
      text: "Lorem ipsum Lorem ipsum Lorem ipsum v Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum vLorem ipsum Lorem ipsum Lorem ipsum Lorem ipsumLorem ipsum",
      time: "il y a 2 semaines",
    },
  ];

  const clients = [
    {
      image: "/ellipse-2@2x.png",
      name: "Wade Warren",
      role: "PDG de BATIPRO",
      description:
        "Le lorem ipsum est, en imprimerie, une suite de mots sans signification utilisée à titre provisoire pour calibrer une mise en page",
      time: "Il y a 2 jours",
    },
    {
      image: "/ellipse-2-1@2x.png",
      name: "Wade Warren",
      role: "PDG de BATIPRO",
      description:
        "Le lorem ipsum est, en imprimerie, une suite de mots sans signification utilisée à titre provisoire pour calibrer une mise en page",
      time: "Il y a 2 jours",
    },
    {
      image: "/ellipse-2-1@2x.png",
      name: "Wade Warren",
      role: "PDG de BATIPRO",
      description:
        "Le lorem ipsum est, en imprimerie, une suite de mots sans signification utilisée à titre provisoire pour calibrer une mise en page",
      time: "Il y a 2 jours",
    },
    {
      image: "/ellipse-2-3@2x.png",
      name: "Wade Warren",
      role: "PDG de BATIPRO",
      description:
        "Le lorem ipsum est, en imprimerie, une suite de mots sans signification utilisée à titre provisoire pour calibrer une mise en page",
      time: "Il y a 2 jours",
    },
  ];

  const [allBlogs, setAllBlogs] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  const videosRef = useRef(null);

  const {
    isPending: isPendingBlogs,
    //error: errorBlogs,
    data: dataBlogs,
    isSuccess: isSuccessBlogs,
  } = useQuery({
    enabled: true,
    queryKey: ["getSavoirFaireBlogs", page],
    queryFn: () => getBlogs({ page, limit: 3, category: "savoir-faire" }),
  });

  const settings = {
    className:
      "h-[200vh] md:h-[75vh] w-[calc(100vw-2*theme('spacing.10'))] md:w-[calc(100vw-2*theme('spacing.40'))] bg-transparent",
    dots: false,
    useTransform: true,
    infinite: true,
    currentSlide: 0,
    initialSlide: 0,
    autoplay: true,
    autoplaySpeed: 3000,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    swipeToSlide: true,
    adaptiveHeight: true,
    arrows: false,
    beforeChange: useCallback(
      (current, next) => {
        if (allBlogs.find((item) => item.page === next + 1) === undefined) {
          setPage(next + 1);
        }
      },
      [allBlogs],
    ),
  };

  function getMap() {
    if (!videosRef.current) {
      // Initialize the Map on first usage.
      videosRef.current = new Map();
    }
    return videosRef.current;
  }
  const handlePlayPauseVideo = useCallback((video) => {
    const map = getMap();
    const node = map.get(video);

    if (node.paused) node.play();
    else node.pause();
  }, []);

  const generateSlidesArray = useMemo(() => {
    return [...Array(totalPages).keys()].map((i) => i + 1);
  }, [totalPages]);

  const getBlogsOnPage = useCallback(
    (page) => {
      const pageBlogs = allBlogs.find((item) => item.page === page);
      return pageBlogs !== undefined ? pageBlogs.blogs : [];
    },
    [allBlogs],
  );

  useEffect(() => {
    if (isSuccessBlogs) {
      setTotalPages(dataBlogs.totalPages);

      if (allBlogs.find((item) => item.page === page) === undefined) {
        if (dataBlogs.page === page)
          setAllBlogs((prevState) => {
            return [...prevState, { page, blogs: dataBlogs.blogs }];
          });
      }
    }
  }, [allBlogs, dataBlogs, isSuccessBlogs, page]);

  return (
    <div className="relative w-full bg-white  flex flex-col items-center font-urbanist">
      <div className="flex flex-col items-center">
        <span className="text-4xl font-medium">Savoir-Faire</span>
        <span className="text-lg font-light">
          Notre savoir-faire à travers notre expertise
        </span>
      </div>
      <div className="my-20  w-full flex flex-col items-start gap-10 ">
        <span className="px-10 md:px-52 text-3xl text-black">
          {"Notre expertise"}
        </span>
        <div className="px-10 md:px-40 slider-container bg-transparent">
          <Slider {...settings}>
            {generateSlidesArray.map((item) => (
              <div
                key={`savoir-faire-slide-${item}`}
                className="bg-transparent p-10"
              >
                {isPendingBlogs && item === page ? (
                  <div className="bg-gray-100 animate-pulse h-[80vh]" />
                ) : (
                  <div className="w-full h-full md:h-[80vh] grid grid-cols-1 md:grid-rows-2 md:grid-flow-col  gap-x-10 gap-y-5 ">
                    {getBlogsOnPage(item).map((blog, index) =>
                      index === 0 ? (
                        <div
                          key={`latest-blogs-savoir-faire-${item}-${index}`}
                          className="bg-white shadow-xl shadow-orange-100 rounded-sm md:row-span-2 md:col-span-2 flex flex-col"
                        >
                          <div
                            className="w-full h-[60vh] md:h-[65%] rounded-sm"
                            style={{
                              backgroundImage: `url(${blog.image})`,
                              backgroundSize: "cover",
                            }}
                          ></div>
                          <div className="p-10 flex flex-col items-start gap-5">
                            <div className="flex flex-col md:flex-row gap-5 md:items-center">
                              <span className="w-fit font-semibold font-montserrat text-xl ">
                                {blog.title}
                              </span>
                              <span className="w-fit text-[#3D75B0]  font-montserrat text-sm ">
                                {getTimeDifference(blog.updatedAt)}
                              </span>
                            </div>
                            <div className="flex flex-col md:flex-row justify-between w-full gap-2 md:gap-0">
                              <span className="text-base text-black text-opacity-60  line-clamp-5 max-w-sm">
                                {blog.description}
                              </span>
                              <a
                                href={`/blog/${blog.slug}`}
                                className="md:self-end font-poppins text-[#3D75B0]"
                              >
                                <div className="flex flex-row gap-1 text-xs font-medium items-center">
                                  <span>Tout voir</span>
                                  <FaArrowRight />
                                </div>
                              </a>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div
                          key={`latest-blogs-savoir-faire-${item}-${index}`}
                          className="bg-white shadow-xl shadow-orange-100 px-1.5 rounded-md  flex flex-col"
                        >
                          <div
                            className="w-full h-[30vh] md:h-full rounded-md"
                            style={{
                              backgroundImage: `url(${blog.image})`,
                              backgroundSize: "cover",
                            }}
                          ></div>
                          <div className="py-2 px-2 h-full flex flex-col items-start gap-1 ">
                            <span className="w-fit text-[#3D75B0]  font-montserrat text-sm ">
                              {getTimeDifference(blog.updatedAt)}
                            </span>
                            <span className="text-xl font-semibold line-clamp-1">
                              {blog.title}
                            </span>
                            <div className="flex flex-row gap-8  w-full">
                              <span className="text-sm max-w-44 text-black text-opacity-60 line-clamp-3">
                                {blog.description}
                              </span>

                              <a
                                href={`/blog/${blog.slug}`}
                                className="self-end font-poppins text-[#3D75B0]"
                              >
                                <div className="flex flex-row gap-1 text-xs font-medium items-center">
                                  <span>Tout voir</span>
                                  <FaArrowRight />
                                </div>
                              </a>
                            </div>
                          </div>
                        </div>
                      ),
                    )}
                  </div>
                )}
              </div>
            ))}
          </Slider>
        </div>

        <span className="px-10 md:px-52 mt-40 mb-20 text-3xl text-black">
          {"Notre garage et équipements"}
        </span>
      </div>
      <div className="h-[130vh] md:h-[85vh] w-full bg-[#F9F1EA]" />

      <div className="absolute top-[44%] md:top-[40%] z-[1] w-[70vw] h-[100vh] grid grid-cols-1 md:grid-cols-3 md:grid-flow-row   gap-x-2 gap-y-2 md:gap-y-0 ">
        {videos.map((video, index) => (
          <Fragment key={`savoir-faire-video-${index}`}>
            {index === 0 ? (
              <div
                key={`video-${index}`}
                className="md:row-span-2 md:col-span-3 h-[60vh] w-full rounded-xl opacity-80 relative"
              >
                <span className="absolute text-center text-xs md:text-base text-white font-semibold max-w-2xl  md:left-40 top-12 ">
                  {video.text}
                </span>
                <button
                  onClick={() => {
                    handlePlayPauseVideo(video);
                  }}
                  className="absolute left-[35%] md:left-[45%] top-48 z-[1]"
                >
                  <FaCirclePlay className=" h-20 w-20 md:h-24 md:w-24 text-white text-opacity-80" />
                </button>
                <video
                  className="object-cover h-full w-full rounded-xl "
                  ref={(node) => {
                    const map = getMap();
                    if (node) {
                      map.set(video, node);
                    } else {
                      map.delete(video);
                    }
                  }}
                >
                  <source
                    src={video.link}
                    type="video/mp4"
                    className="h-[50vh] w-full"
                  />
                  <track kind="captions" />
                  Your browser does not support the video tag.
                </video>
              </div>
            ) : (
              <div
                key={`video-${index}`}
                className="flex flex-col p-1.5 shadow-xl shadow-orange-100 bg-white rounded-xl relative"
              >
                <button
                  onClick={() => {
                    handlePlayPauseVideo(video);
                  }}
                  className="absolute left-[45%] top-16   z-[1]"
                >
                  <FaCirclePlay className=" h-8 w-8 text-white text-opacity-80" />
                </button>
                <div className="h-[60%]">
                  <video
                    className="object-cover h-full w-full rounded-xl line-clamp-2"
                    ref={(node) => {
                      const map = getMap();
                      if (node) {
                        map.set(video, node);
                      } else {
                        map.delete(video);
                      }
                    }}
                  >
                    <source
                      src={video.link}
                      type="video/mp4"
                      className="h-[50vh] w-full"
                    />
                    <track kind="captions" />
                    Your browser does not support the video tag.
                  </video>
                </div>
                <div className="flex flex-col py-2 px-5 gap-2 ">
                  <span className="line-clamp-2 text-lg leading-5 max-w-48">
                    {video.text}
                  </span>
                  <span className="line-clamp-2 text-xs text-main-orange max-w-48">
                    {video.time}
                  </span>
                </div>
              </div>
            )}
          </Fragment>
        ))}
      </div>

      <div className="bg-white w-full flex flex-col gap-16 items-start px-10 md:px-52 my-52">
        <span className="text-3xl text-black">{"Nos clients disent"}</span>
        <div className="grid grid-cols-1 md:grid-cols-2 md:grid-flow-row  gap-12 w-full  md:h-[65vh]">
          {clients.map((client, index) => (
            <div
              key={`client-testimonials-${index}`}
              className="bg-white  shadow-lg shadow-gray-400/80 flex flex-col gap-8 p-5 font-inter"
            >
              <div className="flex flex-row items-center gap-5">
                <div className="bg-main-orange rounded-full p-0.5">
                  <img
                    className=" h-16 w-16 rounded-full overflow-hidden  object-cover"
                    loading="eager"
                    alt=""
                    src={client.image}
                  />
                </div>
                <div className="flex flex-col">
                  <span className="text-lg font-medium">{client.name}</span>
                  <span className="text-base text-black text-opacity-60">
                    {client.role}
                  </span>
                </div>
              </div>
              <div className="flex flex-col gap-2">
                <span className="text-base text-black max-w-sm line-clamp-3">
                  {client.description}
                </span>
                <span className="text-sm text-black text-opacity-60">
                  {client.time}
                </span>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default CultureSavoirFaire;
