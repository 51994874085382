import { Header } from "../Header";
import NosChantiersFrame from "../NosChantiersFrame";
import { DotsIndicator } from "../DotsIndicator";
import PartnersFrameQuotidien from "./PartnersFrameQuotidien";
import { useCallback, useEffect, useMemo, useState } from "react";

const SliderComponent = () => {
  const slides = useMemo(() => {
    return ["/home-1.jpeg", "/home-2.jpeg", "/home-3.jpeg", "/home-4.jpeg"];
  }, []);

  const [currentIndex, setCurrentIndex] = useState(0);
  const [loadedImages, setLoadedImages] = useState({});

  useEffect(() => {
    const preloadImages = async () => {
      const imagePromises = slides.map((src) => {
        return new Promise((resolve) => {
          const img = new Image();
          img.src = src;
          img.onload = () => {
            setLoadedImages((prev) => ({
              ...prev,
              [src]: true,
            }));
            resolve();
          };
          img.onerror = () => {
            console.error(`Failed to load image: ${src}`);
            resolve(); // Resolve to avoid blocking other images
          };
        });
      });

      await Promise.all(imagePromises);
    };

    preloadImages();
  }, [slides]);

  const nextSlide = useCallback(() => {
    const isLastSlide = currentIndex === slides.length - 1;
    const newIndex = isLastSlide ? 0 : currentIndex + 1;
    setCurrentIndex(newIndex);
  }, [currentIndex, slides.length]);

  useEffect(() => {
    const intervalId = setInterval(nextSlide, 4000);
    return () => clearInterval(intervalId);
  }, [currentIndex, nextSlide]);

  return (
    <section className="w-full max-w-full flex flex-col relative mb-[120vh] md:mb-56">
      <div
        className="relative w-full bg-cover duration-1000"
        style={{
          backgroundImage: loadedImages[slides[currentIndex]]
            ? `url(${slides[currentIndex]})`
            : undefined,
          opacity: loadedImages[slides[currentIndex]] ? 1 : 0.8,
          transition: "opacity 0.5s ease-in-out",
        }}
      >
        {/* Fallback Dark Blurred Background */}
        {!loadedImages[slides[currentIndex]] && (
          <div
            className="absolute inset-0 bg-gray-300 blur-md"
            style={{ zIndex: -1 }}
          />
        )}

        <div className="flex flex-col px-5 md:px-24 py-5 md:py-10">
          <Header />
          <NosChantiersFrame />
          <div className="absolute p-5 bottom-4 right-0">
            <DotsIndicator slides={slides} currentIndex={currentIndex} />
          </div>
        </div>
      </div>
      <PartnersFrameQuotidien />
    </section>
  );
};

export default SliderComponent;
