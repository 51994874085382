import React, { useCallback, useEffect, useState } from "react";
import HeroSection from "../components/HeroSection";
import Footer from "../components/Footer";
import TransportComponent from "../components/expertisePage/TransportComponent";
import CompaniesComponent from "../components/expertisePage/CompaniesComponent";

const Expertise = () => {
  const slides = ["/home-3.png", "/home-4.png", "/home-2.png"];
  const [currentIndex, setCurrentIndex] = useState(0);

  const nextSlide = useCallback(() => {
    const isLastSlide = currentIndex === slides.length - 1;
    const newIndex = isLastSlide ? 0 : currentIndex + 1;
    setCurrentIndex(newIndex);
  }, [currentIndex, slides.length]);

  useEffect(() => {
    // Set an interval to call autoChangeSlide every 3 seconds
    const intervalId = setInterval(nextSlide, 3000);

    // Clean up the interval when the component unmounts
    return () => clearInterval(intervalId);
  }, [currentIndex, nextSlide]);

  return (
    <div className="w-screen  bg-white  flex flex-col overflow-hidden">
      <HeroSection url={"/home-3.jpeg"}>
        <div className="rounded-xl bg-main-orange bg-opacity-65 mt-[25vh] md:mt-[50vh] mb-36 ml-0 md:ml-40 p-10    w-fit max-w-lg  flex flex-row items-start justify-start text-left  text-white font-urbanist">
          <h1 className="relative  font-light  leading-snug text-2xl md:text-4xl ">
            <span className="font-medium">Notre expertise & nos services</span>
            <p className="mt-5 text-base">
              {
                "Nous offrons une gamme complète de services qui font de nous un partenaire de choix, capable de vous soutenir à chaque étape de vos opérations logistiques."
              }
            </p>
          </h1>
        </div>
      </HeroSection>

      <TransportComponent />
      <CompaniesComponent />

      <Footer />
    </div>
  );
};

export default Expertise;
