import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import clsx from "clsx";

const FrameComponent = () => {
  const navigate = useNavigate();
  const [currentIndex, setCurrentIndex] = useState(0);

  const data = [
    { image: "/expertise-1.png" },
    { image: "/expertise-2.png" },
    { image: "/expertise-3.png" },
  ];

  useEffect(() => {
    // Function to increment currentIndex and handle the maximum value (2 in this case)
    const incrementIndex = () => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % 3);
    };

    // Set interval to increment currentIndex every 3 seconds
    const intervalId = setInterval(incrementIndex, 3000);

    // Clean up the interval on component unmount
    return () => clearInterval(intervalId);
  }, []);

  const getStyle = useCallback(
    (index) => {
      switch (index) {
        // first image
        case 0:
          switch (currentIndex) {
            // first image is selected
            case 0:
              // return big size
              return {
                top: "top-[0%]",
                left: "left-[0%]",
                width: "w-[60%]",
                height: "h-[100%]",
                zIndex: "z-[3]",
              };
            // second image is selected
            case 1:
              // return medium size
              return {
                top: "top-[10%]",
                left: "-left-[5%]",
                width: "w-[50%]",
                height: "h-[80%]",
                zIndex: "z-[2]",
              };
            // third image is selected
            case 2:
              // return small size
              return {
                top: "top-[20%]",
                left: "left-[0%]",
                width: "w-[45%]",
                height: "h-[60%]",
                zIndex: "z-[1]",
              };
            default:
              return {};
          }
        // second image
        case 1:
          switch (currentIndex) {
            // first image is selected
            case 0:
              // return medium size
              return {
                top: "top-[10%]",
                left: "left-[35%]",
                width: "w-[50%]",
                height: "h-[80%]",
                zIndex: "z-[2]",
              };
            // second image is selected
            case 1:
              // return big size
              return {
                top: "top-[0%]",
                left: "left-[20%]",
                width: "w-[60%]",
                height: "h-[100%]",
                zIndex: "z-[3]",
              };
            // third image is selected
            case 2:
              // return medium size
              return {
                top: "top-[10%]",
                left: "left-[15%]",
                width: "w-[50%]",
                height: "h-[80%]",
                zIndex: "z-[2]",
              };
            default:
              return {};
          }
        // third image
        case 2:
          switch (currentIndex) {
            // first image is selected
            case 0:
              // return small size
              return {
                top: "top-[20%]",
                left: "left-[60%]",
                width: "w-[45%]",
                height: "h-[60%]",
                zIndex: "z-[1]",
              };
            // second image is selected
            case 1:
              // return medium size
              return {
                top: "top-[10%]",
                left: "left-[55%]",
                width: "w-[50%]",
                height: "h-[80%]",
                zIndex: "z-[2]",
              };
            // third image is selected
            case 2:
              // return big size
              return {
                top: "top-[0%]",
                left: "left-[39%]",
                width: "w-[60%]",
                height: "h-[100%]",
                zIndex: "z-[3]",
              };
            default:
              return {};
          }
        default:
          return {};
      }
    },
    [currentIndex],
  );

  return (
    <section className="w-full p-10 md:py-28 md:pl-28 flex flex-col md:flex-row box-border text-left text-black font-urbanist">
      <div className="w-full md:w-2/5 flex flex-col justify-center">
        {/* Content for the first div */}
        <h1 className="m-0 max-w-64  text-4xl text-inherit font-bold font-inherit">
          Expertise et équipement
        </h1>

        <div className="text-lg max-w-sm mt-10">
          <p className="m-0">{`Nous fournissons une gamme complète d'équipements pour le BTP et le transport, incluant des engins robustes et fiables de toutes provenances et de qualité premium. `}</p>
          <br />
          <p className="m-0">{`Découvrez notre atelier doté d'experts en mécanique, prêts à assurer l'entretien et la réparation avec un haut niveau de compétence.`}</p>
        </div>
        <button
          className="cursor-pointer bg-white py-3 px-16 mt-16 w-fit rounded-lg shadow-md hover:bg-gray-50 text-real-main-color font-inter font-normal text-lg"
          onClick={() => {
            navigate("/expeertise");
          }}
        >
          Tout voir
        </button>
      </div>
      <div className="mt-16 md-mt-0 w-full md:w-3/5 h-full flex justify-center self-end">
        {/* Content for the second div */}
        <div className="relative h-[60vh] w-full flex flex-row items-center max-w-full">
          {data.map((dt, index) => (
            <img
              key={index}
              className={clsx(
                getStyle(index).left,
                getStyle(index).top,
                getStyle(index).height,
                getStyle(index).width,
                getStyle(index).zIndex,
                `absolute inset-0 rounded-xl max-w-full overflow-hidden max-h-full object-cover`,
              )}
              loading="eager"
              alt=""
              src={dt.image}
              style={{
                border: `${
                  currentIndex === index ? "2px solid #FFFFFF" : "0px"
                }`,
              }}
            />
          ))}
        </div>
      </div>
    </section>
  );
};

export default FrameComponent;
