import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { FaRegCircleUser } from "react-icons/fa6";
import Slider from "react-slick";
import { getBlogBySlug, getBlogs } from "../../api";
import { useQuery } from "@tanstack/react-query";
import { format } from "date-fns";
import { fr } from "date-fns/locale";

import "quill/dist/quill.core.css";
import "../../quillContent.css";
import clsx from "clsx";
import { isLessThanTwoWeeks } from "../../utils";

const CultureActualites = () => {
  const titleRef = useRef(null);

  const [selectedBlogSlug, setSelectedBlogSlug] = useState("");
  const [selectedBlog, setSelectedBlog] = useState(null);
  const [allBlogs, setAllBlogs] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  const {
    isPending: isPendingBlogs,
    //error: errorBlogs,
    data: dataBlogs,
    isSuccess: isSuccessBlogs,
  } = useQuery({
    enabled: true,
    queryKey: ["getActualitesBlogs", page],
    queryFn: () => getBlogs({ page, limit: 7, category: "actualites" }),
  });

  const {
    //isPending: isPendingSelectedBlog,
    //error: errorSelectedBlog,
    data: dataSelectedBlog,
    isSuccess: isSuccessSelectedBlog,
  } = useQuery({
    enabled: selectedBlogSlug.length > 0,
    queryKey: ["getActualitesSelectedBlog", selectedBlogSlug],
    queryFn: () => getBlogBySlug(selectedBlogSlug),
  });

  const settings = {
    className:
      "h-[370vh] md:h-[70vh] w-[calc(100vw-2*theme('spacing.10'))] md:w-[calc(100vw-2*theme('spacing.40'))] bg-transparent",
    dots: false,
    useTransform: true,
    infinite: true,
    currentSlide: 0,
    initialSlide: 0,
    autoplay: true,
    autoplaySpeed: 3000,
    cssEase: "linear",
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    swipeToSlide: true,
    adaptiveHeight: true,
    arrows: false,
    swipe: false,
    beforeChange: useCallback(
      (current, next) => {
        if (allBlogs.find((item) => item.page === next + 1) === undefined) {
          setPage(next + 1);
        }
      },
      [allBlogs],
    ),
  };

  const generateSlidesArray = useMemo(() => {
    return [...Array(totalPages).keys()].map((i) => i + 1);
  }, [totalPages]);

  const getBlogsOnPage = useCallback(
    (page) => {
      const pageBlogs = allBlogs.find((item) => item.page === page);
      return pageBlogs !== undefined ? pageBlogs.blogs : [];
    },
    [allBlogs],
  );

  const scrollToTitle = () => titleRef.current.scrollIntoView();

  useEffect(() => {
    if (isSuccessBlogs) {
      //setBlogs(dataBlogs.blogs);
      setTotalPages(dataBlogs.totalPages);
      if (
        dataBlogs.blogs.length > 0 &&
        page === 1 &&
        selectedBlogSlug.length === 0
      ) {
        setSelectedBlogSlug(dataBlogs.blogs[0].slug);
      }

      //console.log(allBlogs.find((item) => item.page === page));

      if (allBlogs.find((item) => item.page === page) === undefined) {
        if (dataBlogs.page === page)
          setAllBlogs((prevState) => {
            return [...prevState, { page, blogs: dataBlogs.blogs }];
          });
      }
    }
  }, [allBlogs, dataBlogs, isSuccessBlogs, page, selectedBlogSlug.length]);

  useEffect(() => {
    if (isSuccessSelectedBlog) {
      setSelectedBlog(dataSelectedBlog.blog);
    }
  }, [dataSelectedBlog, isSuccessSelectedBlog]);

  return (
    <div className="w-full bg-white overflow-hidden flex flex-col items-center font-urbanist">
      {selectedBlog != null ? (
        <>
          <div ref={titleRef} className="flex flex-col items-center">
            <span className="text-4xl font-medium">
              {selectedBlog.category.label}
            </span>
            <span className="text-lg font-light">
              {selectedBlog.category.description}
            </span>
          </div>
          <div className="my-20 flex flex-col items-center gap-10 px-10 md:px-52">
            <span className="font-patrick-hand text-xl text-black text-opacity-60">
              {format(selectedBlog.updatedAt, "dd MMMM yyyy", {
                locale: fr,
              }).toUpperCase()}
            </span>
            <span className="text-4xl text-main-orange font-medium font-inter text-center max-w-3xl">
              {selectedBlog?.title ?? ""}
            </span>
            <div className="flex flex-row items-center gap-3 font-roboto">
              <FaRegCircleUser className="h-12 w-12" />
              <div className="flex flex-col">
                <span className="text-base font-medium">{`${
                  selectedBlog?.createdBy?.firstName ?? "-"
                } ${selectedBlog?.createdBy?.lastName ?? "-"}`}</span>
                <span className="text-sm text-black text-opacity-60">
                  Direction commerciale
                </span>
              </div>
            </div>
            <img
              className=" h-[70vh] w-screen rounded-md overflow-hidden  object-cover"
              loading="eager"
              alt=""
              src={selectedBlog.image}
            />
            <div className="ql-container ql-snow">
              <div
                className="ql-editor"
                dangerouslySetInnerHTML={{
                  __html: selectedBlog?.blogBody ?? "",
                }}
              />
            </div>
          </div>
        </>
      ) : (
        <div className="h-[100vh] w-full" />
      )}

      <div className="w-full bg-[#F9F1EA] pt-20 pb-40 px-10 md:px-52 flex flex-col gap-20 items-center">
        <span className="text-4xl font-medium">{"Découvrez aussi..."}</span>
        <div className="px-10 md:px-40 slider-container bg-transparent">
          <Slider {...settings}>
            {generateSlidesArray.map((item) => (
              <div
                key={`actualite-grid-${item}`}
                className="bg-transparent p-10"
              >
                {isPendingBlogs && item === page ? (
                  <div className="bg-gray-100 animate-pulse h-[70vh]" />
                ) : (
                  <div className="w-full md:h-[70vh] grid grid-cols-1 md:grid-rows-2 md:grid-flow-col md:auto-cols-fr gap-5 ">
                    {getBlogsOnPage(item).map((blog, index) => (
                      <div
                        key={`latest-blogs-actualites-${item}-${index}`}
                        role={"presentation"}
                        className={clsx(
                          blog.slug !== selectedBlogSlug && "cursor-pointer",
                          index === 0 ? "md:row-span-2 p-5" : "p-3",
                          "bg-white border flex flex-col",
                        )}
                        onClick={() => {
                          if (blog.slug !== selectedBlogSlug) {
                            setSelectedBlogSlug(blog.slug);
                            scrollToTitle();
                          }
                        }}
                      >
                        <div
                          className={clsx(
                            index === 0
                              ? "h-[50vh] md:h-[65%]"
                              : "h-[20vh] md:h-full",
                            "w-full  rounded-sm",
                          )}
                          style={{
                            backgroundImage: `url(${blog.image})`,
                            backgroundSize: "cover",
                          }}
                        ></div>
                        <div
                          className={clsx(
                            index === 0 ? "mt-8" : "mt-3 md:h-full",
                            "flex flex-col items-start justify-center gap-5 ",
                          )}
                        >
                          <span
                            className={clsx(
                              !isLessThanTwoWeeks(blog.updatedAt) && "hidden",
                              "w-fit px-2 py-1 font-light text-sm bg-[#F6F1DD]",
                            )}
                          >
                            Nouveauté
                          </span>
                          <span className="text-base font-semibold line-clamp-3">
                            {blog.title}
                          </span>
                          <span className="text-sm text-black text-opacity-60">
                            {format(blog.updatedAt, "dd MMMM yyyy", {
                              locale: fr,
                            })}
                          </span>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </div>
  );
};

export default CultureActualites;
{
  /* <div dangerouslySetInnerHTML={formattedJobDescription()} /> */
}
