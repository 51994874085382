import Footer from "../components/Footer";
import { Header } from "../components/Header";
import clsx from "clsx";
import { useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { getBlogBySlug } from "../api";
import { useEffect, useState } from "react";

import "quill/dist/quill.core.css";
import "../quillContent.css";

const Projets = () => {
  let { slug } = useParams();

  const { isPending, error, data, isSuccess } = useQuery({
    queryKey: ["getCountryBlog"],
    queryFn: () => getBlogBySlug(slug),
  });

  const [blog, setBlog] = useState(null);

  useEffect(() => {
    if (isSuccess) {
      //console.log(data);
      setBlog(data.blog);
    }
  }, [data, isSuccess]);

  return (
    <div className="w-screen  bg-white  flex flex-col overflow-hidden font-urbanist">
      <div className="w-full flex flex-col">
        <div className="relative h-[70vh] w-full bg-[#59AAD6] flex flex-col px-7 md:px-20 py-5">
          <Header />

          <div className="absolute inset-0 top-44 mx-auto grid grid-cols-1 md:grid-cols-2 gap-5 h-[35vh] md:h-[70vh] w-[80vw] ">
            <div className={clsx("h-full w-full", "", "")}>
              <img
                className={clsx("h-full w-full", "my-auto")}
                loading="eager"
                alt=""
                src="/bridge-1.png"
              />
            </div>
            <div className="grid grid-cols-2  gap-5 ">
              <div className={clsx("h-[17.5vh] md:h-[35vh] w-full")}>
                <img
                  className={clsx("h-full w-full", "my-auto")}
                  loading="eager"
                  alt=""
                  src="/bridge-2.png"
                />
              </div>
              <div className={clsx("h-[17.5vh] md:h-[35vh] w-full")}>
                <img
                  className={clsx("h-full w-full", "my-auto")}
                  loading="eager"
                  alt=""
                  src="/bridge-3.png"
                />
              </div>
              <div className={clsx("h-[17.5vh] md:h-[35vh] w-full")}>
                <img
                  className={clsx(" h-full w-full object-cover", "my-auto")}
                  loading="eager"
                  alt=""
                  src="/bridge-4.png"
                />
              </div>
              <div className={clsx("h-[17.5vh] md:h-[35vh] w-full")}>
                <img
                  className={clsx("h-full w-full", "my-auto")}
                  loading="eager"
                  alt=""
                  src="/bridge-5.png"
                />
              </div>
            </div>
          </div>
        </div>
        {isPending || error !== null || blog === null ? (
          <div className="h-[50vh] w-full bg-white" />
        ) : (
          <div className="min-h-[50vh] w-full bg-white px-7 md:px-48 mt-72 mb-32 flex flex-col gap-20">
            <h3 className="text-4xl font-medium">{blog?.title ?? "-"}</h3>

            <div className="ql-container ql-snow">
              <div
                className="ql-editor"
                dangerouslySetInnerHTML={{ __html: blog?.blogBody ?? "" }}
              />
            </div>
          </div>
        )}
      </div>
      <Footer />
    </div>
  );
};

export default Projets;
