import React from "react";

const TeamComponent = () => {
  const teamMembers = [
    {
      name: "Akinotcho Moussa",
      role: "CEO/ Président Directeur Général",
      image: "/man-profile.jpeg",
    },
    {
      name: "CHAKIROU DRAMANI",
      role: "DGA",
      image: "/man-profile.jpeg",
    },
    {
      name: "AKINOTCHO DJIHANE",
      role: "Directeur Général",
      image: "/woman-profile.jpeg",
    },
    {
      name: "Franck MEGNIKPA",
      role: "Directeur administratif  ",
      image: "/man-profile.jpeg",
    },
    {
      name: "Schélach DOVOEDO",
      role: "Directeur administratif ",
      image: "/woman-profile.jpeg",
    },
    {
      name: "PHILLIPE ERIBON",
      role: "Meilleur camionneur",
      image: "/man-profile.jpeg",
    },
    {
      name: "ESTHER ADOTO",
      role: "Meilleur camionneur",
      image: "/woman-profile.jpeg",
    },
    /*    {
      name: "Jacob Jones",
      role: "Starbucks",
      image: "/new/team-member-8.png",
    },*/
  ];

  return (
    <section className="flex flex-col font-urbanist items-center py-24 ">
      <h3 className="text-4xl">Notre équipe</h3>
      <p className="mt-10 text-base text-center">
        {`Nous visons à soutenir le développement économique et social des pays de la sous région en assurant des services fiables et innovants.`}{" "}
        <br />
        {`Rencontrez notre équipe !`}
      </p>
      <div className="mt-10 grid grid-cols-1 md:grid-cols-4 gap-10">
        {teamMembers.map((member, index) => (
          <div
            key={`team-member-${index}`}
            className="flex flex-col w-56 min-h-96 font-montserrat"
          >
            <img
              className="relative w-full h-80 overflow-hidden object-cover"
              loading="eager"
              alt=""
              src={member.image}
            />
            <span className="mt-2.5 uppercase font-semibold">
              {member.name}
            </span>
            <span className="mt-1.5 text-sm text-gray-400">{member.role}</span>
          </div>
        ))}
      </div>
    </section>
  );
};

export default TeamComponent;
