import React from "react";

const CompaniesComponent = () => {
  return (
    <section className="flex flex-col px-10 md:px-0 py-10 md:py-20  font-urbanist gap-10 ">
      <div className=" flex flex-col h-[110vh] md:h-full md:flex-row gap-20 md:gap-0 md:justify-evenly">
        <div className="w-full md:w-fit flex flex-col gap-10">
          <h3 className="max-w-sm text-4xl">Straca Oil & distributions</h3>
          <p className="max-w-md">
            {
              "Lörem ipsum jovis dåvur autojovis för att er men antir tills famibelt nygisk utan pomens. Soren dyskemi trevis triponyment av faling trevis. Lörem ipsum jovis dåvur autojovis för att er men antir tills famibelt nygisk utan pomens. Soren dyskemi trevis triponyment av faling trevis. Lörem ipsum jovis dåvur autojovis för att er men antir tills famibelt nygisk utan pomens. Soren dyskemi trevis triponyment av faling trevis. Lörem ipsum jovis dåvur autojovis för a"
            }
          </p>
          <button className="w-fit py-3 px-7 bg-white text-main-color rounded-xl shadow-xl">
            En savoir plus
          </button>
        </div>
        <div className="w-full md:w-[30vw] relative flex justify-center">
          <div className=" absolute inset-0 bg-sandybrown w-[85vw] md:w-[27.5vw] h-[45vh] rounded-xl" />
          <img
            className="absolute inset-0 -top-5 left-10 w-[65vw]  md:w-[22.5vw] h-[50vh] object-cover rounded-xl z-[1]"
            loading="eager"
            alt=""
            src="/oil-1.png"
          />
        </div>
      </div>
      <div className=" md:py-48 flex flex-col h-[110vh] md:h-full md:flex-row gap-20 md:gap-0 md:justify-evenly">
        <div className="w-full md:w-[30vw] relative flex justify-center top-[50vh] md:top-0">
          <div className="absolute inset-0 bg-main-color w-[85vw] md:w-[27.5vw] h-[45vh] rounded-xl" />
          <img
            className="absolute inset-0 -top-5 left-10 w-[65vw]  md:w-[22.5vw] h-[50vh] object-cover rounded-xl z-[1]"
            loading="eager"
            alt=""
            src="/transit-1.png"
          />
        </div>
        <div className="w-full md:w-fit flex flex-col gap-10">
          <h3 className="max-w-sm text-4xl">Straca Transit</h3>
          <p className="max-w-md">
            {
              "Nous sommes experts dans la combinaison de différents modes de transport afin de garantir que les marchandises de nos clients atteignent leur destination finale."
            }
          </p>
          <button className="w-fit py-3 px-7 bg-white text-main-color rounded-xl shadow-xl">
            En savoir plus
          </button>
        </div>
      </div>
      <div className="md:py-48 flex flex-col h-[110vh] md:h-full md:flex-row gap-20 md:gap-0 md:justify-evenly">
        <div className="w-full md:w-fit flex flex-col gap-10">
          <h3 className="max-w-sm text-4xl">Aweni BTP</h3>
          <p className="max-w-md">
            {
              "Nous sommes experts dans la combinaison de différents modes de transport afin de garantir que les marchandises de nos clients atteignent leur destination finale. "
            }
          </p>
          <button className="mt-auto w-fit py-3 px-7 bg-white text-main-color rounded-xl shadow-xl">
            En savoir plus
          </button>
        </div>
        <div className="w-full md:w-[30vw] relative flex justify-center">
          <div className=" absolute inset-0 bg-sandybrown w-[85vw] md:w-[27.5vw] h-[45vh] rounded-xl" />
          <img
            className="absolute inset-0 -top-5 left-10 w-[65vw]  md:w-[22.5vw] h-[50vh] object-cover rounded-xl z-[1]"
            loading="eager"
            alt=""
            src="/aweni-1.png"
          />
        </div>
      </div>
    </section>
  );
};

export default CompaniesComponent;
